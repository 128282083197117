import React from "react";
import { Root, Routes } from "react-static";
import { Link } from "@reach/router";
import styled, { ThemeProvider } from "styled-components";
import { Transition, animated } from "react-spring";

import { DarkTheme } from "./Theme";

import Style from "./Style";

const Main = styled.main`
  width: 100vw;
  min-height: 100vh;
`;
function App() {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Root>
        <Style />

        <Main>
          {/* <Routes /> */}
          <Routes>
            {({ routePath, getComponentForPath }) => {
              // Using the routePath as the key, both routes will render at the same time for the transition
              return (
                <Transition
                  native
                  items={routePath}
                  from={{ transform: "translateY(100px)", opacity: 0 }}
                  enter={{ transform: "translateY(0px)", opacity: 1 }}
                  leave={{ transform: "translateY(-100px)", opacity: 0 }}
                >
                  {item => props => {
                    const Comp = getComponentForPath(item);
                    return (
                      <animated.div style={props}>
                        <Comp />
                      </animated.div>
                    );
                  }}
                </Transition>
              );
            }}
          </Routes>
        </Main>
      </Root>
    </ThemeProvider>
  );
}

export default App;
