import { createGlobalStyle } from "styled-components";
import { Reset } from "contist-ui";

export default createGlobalStyle`
    ${Reset}

    @import url('https://fonts.googleapis.com/css?family=Roboto:300,500');

  html, body {
    font-family: 'Roboto', sans-serif;
    background-color: ${p => p.theme.colors.background};
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    font-size: 100%;

    @media (max-width: 44em) {
      font-size: 85%;
    }
  }

  h1,
  h2,
  h3,
  h3 {
    color: #fff;
    font-weight: 500;
  }

  .modal-text {
    h3 {
      font-size: 18px;
      margin: 0;
      padding: 0;
    }
  }

`;
