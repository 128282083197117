import { rgba } from "contist-ui";

export const DarkTheme = {
  colors: {
    primary: "#e4212c",
    primaryLight: "#6760fe",
    primaryLink: "#4f67f2",
    background: "rgba(25,50,80,1)",
    header: "#041A23",
    text: "#FFF",
    lightText: rgba("#FFF", 0.5),
    success: "#4CAF50",
    danger: "#F44336",
    warning: "#FFEB3B",
    primaryGradient: ["#1D57DB", "#6760fe"],
    defaultGradient: ["#275165", "#244F60"],
    tababrGradient: ["#0c344b", "#0c344b"],
    shadow: `3px 5px 5px ${rgba("#000", 0.1)}`,
    shadowColor: rgba("#000", 0.1),
    borderColor: rgba("#FFF", 0.1)
  },
  breakpoints: {
    mb: 0,
    tb: 768,
    dt: 1024
  },
  globals: {
    wrapperWidth: "600px",
    buttonRadius: "2rem",
    buttonColor: "primary",
    buttonSize: "l"
  }
};
